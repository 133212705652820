@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --text-color:#11181c;
  --title-color:#000000;
  --subheading-color:#000000;
  --blue-color:#0070f0;
    overflow-x: hidden;
}

@keyframes marquee {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
}

.gradient-new-text {
  background-image: linear-gradient(to bottom, #0070f0, #67abf9);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

/* styles.css */
.bg-gradient {
  background-image: linear-gradient(to bottom, #0070f0, #67abf9);
}


/* styles/globals.css */
body {
  font-family: 'Noto Sans', sans-serif;
}

/* h1, h2, h3, h4, h5, h6 {
  font-family: 'Noto Sans', sans-serif;
} */

code {
  font-family: 'Noto Sans', sans-serif;
}

::-webkit-scrollbar {
  width: 8px; /* Width of vertical scrollbar */
}
::-webkit-scrollbar-thumb {
  background-color: rgba(113, 128, 150, 0.1); /* Reduced opacity */
  border-radius: 8px; /* Rounded corners */
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(74, 85, 104, 0.1); /* Darker color when hovered with reduced opacity */
}
::-webkit-scrollbar-track {
  background-color: rgba(226, 232, 240, 0.1); /* Reduced opacity */
  border-radius: 8px; /* Rounded corners */
}
::-webkit-scrollbar-track:hover {
  background-color: rgba(203, 213, 224, 0.1); /* Lighter color when hovered with reduced opacity */
}

/*all hyperlink in website on:hover color change */
a {
  @apply text-current;
}
a:hover {
  color: var(--blue-color);
}



html {
  scroll-behavior: smooth;
}



@keyframes popIn {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.animate-pop {
  animation: popIn 0.6s ease-out;
}

.gradient-background {
  background: linear-gradient(to bottom, #0070f0, #67abf9);
}

.background-image-custom {
  background-image: url('https://bizdataset-report.s3.eu-north-1.amazonaws.com/asset/images/world_map.svg');
  opacity: 0.5; /* Adjust opacity as needed */
  background-size: contain; /* Adjust background size to maintain image quality */
  background-repeat: no-repeat; /* Prevent image from repeating */
  background-position: center; /* Center the background image */
}

/* styles.css */

@keyframes marquee-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animate-marquee-right {
  animation: marquee-right 50s linear infinite;
}

.animate-marquee-left {
  animation: marquee-left 50s linear infinite;
}


/* Add this to your CSS file */
.hidden {
  display: none;
}

.whats-new-table {
  border: 1px solid #e5e7eb; /* Tailwind's gray-200 */
  border-radius: 0.5rem; /* Tailwind's rounded-lg */
}




:root {
  --marquee-elements-displayed: 5; /* This should match the number of items you want visible */
}
